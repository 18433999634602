.svg-stroke-primary {
  stroke: var(--py-color-primary-500);
}

.svg-fill-primary {
  fill: var(--py-color-primary-500);
}

.svg-fill-secondary {
  fill: var(--py-color-primary-200);
}

.svg-fill-tertiary {
  fill: var(--py-color-primary-300);
}
